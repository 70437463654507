import { Configuration } from './model';
export const config: Configuration = {
      // apiUrl: 'http://localhost:8000/api',
      // apiUrlImage: 'http://localhost:8000',
      // apiUrlc:'http://localhost:8000',
      // apiSocket:'ws://localhost:8000/',
      // baseUrl: 'http://localhost:8000/',
  


  // apiUrl:'http://api-markpedia.pragmatique.academy:8001/api',
  // apiUrlImage: 'http://api-markpedia.pragmatique.academy:8001'
  
   apiUrl:'https://api.markpedia.com/api',
   apiUrlc:'https://api.markpedia.com',
   baseUrl:'https://api.markpedia.com/',
   apiSocket:'wss://api.markpedia.com/',
   apiUrlImage: 'https://api.markpedia.com'

};

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import {User} from './user'
// import { any } from '../interfaces/user';
import { config } from 'src/config/local';
import * as URLS from 'src/app/constants';
interface Currency {
  base: boolean;
  currency: string;
  symbol: string;
  value?: number;
}
@Injectable({
  providedIn: 'root'
})

// @Injectable()
export class AuthenticationService {
  public host: string = config.apiUrl;
  rabbitSubscription: Subscription;
  rabbitpersonnalSubscription: Subscription;

  isAuth$ = new BehaviorSubject<boolean>(false);
  token: string;
  userId: string;
  pseudo: string;
  activemail: string;
  firstname: string = '';
  imageUrl: string = '';

  userinfo: any;
  isLoadingOnce: boolean = false;
  // private userOnceSubject$ = new BehaviorSubject<boolean>(this.userinfo);
  // userOnceChanged$ = this.userOnceSubject$.asObservable();

  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  public configObservable = new Subject<any>();
  public categories$ = new Subject<any[]>();
  public currency$ = new Subject<Currency>();
  public currencies$ = new Subject<any[]>();

  // baseUrl = "http://localhost:8000/"
   baseUrl =  `${config.apiUrlImage}/`
  //  baseUrl = "http://localhost:8000/"

   //private currentUserSubject = new BehaviorSubject<any>({} as any);
   //public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

   loginStatus = new BehaviorSubject<boolean>(false);

   forgotpassword(query): any {


    return this.httpClient.post<any>(URLS._URL_FORGOT_PASSWORD, query)
        .pipe(map(user => {
            return user;
        }));
}
// fetchInfo(query): any {


//     return this.httpClient.post<any>(URLS._URL_INFO_USER, query)
//         .pipe(map(user => {
//             this.userinfo = user;
//             this.userOnceSubject$.next(this.userinfo);
//             return user;
//         }));
// }
// codeverification(query): any {


//     return this.httpClient.post<any>(URLS._URL_CODE_VERIFICATION, query)
//         .pipe(map(user => {
//             // //console(user)
//             // login successful if there's a jwt token in the response
//             return user;
//         }));
// }
   updateOnCache(user){
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
   }
  
   constructor(private httpClient: HttpClient)
  { 
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
   
    const current_currency = localStorage.getItem('MarkpediaDevise')
    //console("current_currency", current_currency)
    if (JSON.parse(current_currency)) {
      this.currency$.next(JSON.parse(current_currency))
    }else{
      const currency: Currency = {
        currency: 'USD',
        symbol: '$',
        base: true,
        value: 1
      }
      localStorage.setItem('MarkpediaDevise', JSON.stringify(currency))
      this.currency$.next(currency)
    }
   
  //   if (this.currentUserValue) {
  //     this.token = this.currentUserValue.token;
  //     this.userId = this.currentUserValue.userId;
  //     this.pseudo = this.currentUserValue.pseudo;
  //     this.imageUrl = this.currentUserValue.imageUrl;
  //     this.isAuth$.next(true);

  //    // this.initRabbitWatch();
  // }
  }

  getsCurrencyDevice(devise: string) {
    const myurl = `https://openexchangerates.org/api/latest.json?app_id=d73ddd997bda42dab524aa930edd69b5&symbols=${devise}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  public login(url: string, user: any): Observable<any> {
    return this.httpClient
      .post<any>(this.host+url, user, {responseType: 'json'})
      .pipe(map((response: any) => {
        //console(response);
        this.loginStatus.next(true);
        //console("SUCCES LOGIN");
        localStorage.setItem("markpediausersessionid",JSON.stringify(response))
        this.currentUserSubject.next(response);

                   
        //console(this.currentUserSubject);

        return response;
      }));
  }

  public postSignUp(url: string, user: any): Observable<any> {
    // const host="http://localhost:8000/api"
    const host="http://localhost:8000/api"
    return this.httpClient
      .post<any>(this.host+url, user)
      .pipe(map((response: any) => {
        //console(response);

        // localStorage.setItem("markpediausersessionid",JSON.stringify(response))
        this.currentUserSubject.next(response);

                   
        //console(this.currentUserSubject);

        return response;
      }));
  }

  
  
  createNewUser(url: string, user: any) {

    let params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const myurl = `${this.baseUrl}${url}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.post(myurl, user, options)
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  upDateUser(url: string, user: any) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.put(
        myurl, user)
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  getsEntites(url: string) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  getsEntite(url: string) {
    const myurl = `${this.baseUrl}${url}/`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  getEntity(url: string, entity: any) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.post(
        myurl, entity)
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  confirmAccount(url: string) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe(
        (response: any) => {
        resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  forgotPassword(url: string, email: string) {
    const myurl = `${this.baseUrl}${url}`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.post(
        myurl,
        {email : email})
        .subscribe(
          (response: any) => {
           resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getUserById(url: string, id: string) {
    const myurl = `${this.baseUrl}${url}${id}/`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.get(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  deleteEntityById(url: string, id: string) {
    const myurl = `${this.baseUrl}${url}${id}/`;
    return new Promise<void>((resolve, reject) => {
      this.httpClient.delete(myurl).subscribe(
        (user: any) => {
            resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

 

 

}
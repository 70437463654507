import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import * as $ from 'jquery';
import { DOCUMENT } from '@angular/common';
import { AppService } from './app.service';
import { AuthenticationService } from './auth.service';

declare let gtag: Function;

  // declare ga as a function to set and sent the events
 declare let ga: Function;
// declare var user;
const config = {
  apiKey: 'YOUR_API_KEY',
  databaseURL: 'YOUR_DATABASE_URL'
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'], encapsulation: ViewEncapsulation .None,
})
export class AppComponent {
  loading: boolean = false;
  public settings: Settings;
  public showOptions:boolean = false;
  userProfile;
  user;
  routerSubscription: any; 
  title = 'dyncss';

  paymentHandler: any = null;
// tslint:disable-next-line:ban-types
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  constructor(public appSettings: AppSettings, 
    @Inject(DOCUMENT) private document: Document,
              public router: Router,
              public appService: AppService,
              public authService: AuthenticationService,
              @Inject(PLATFORM_ID) private platformId: Object,
              public translate: TranslateService){
    this.settings = this.appSettings.settings;
                this.setUpAnalytics()
    translate.addLangs(['en','fr','chn']);
    translate.setDefaultLang('en'); 
    translate.use('en'); 
    this.recallJsFuntions();

     // subscribe to router events and send page views to Google Analytics
    //  this.router.events.subscribe(event => {

    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');

    //   }

    // });
  }
  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(filter (event => event instanceof NavigationEnd))
      .subscribe(event => {
        // $.getScript('assets/js/bootstrap.min.js');
        // $.getScript('assets/js/themejs/libs.js');
        // $.getScript('assets/js/themejs/application.js');
        // $.getScript('assets/js/themejs/homepage.js'); 
        // $.getScript('assets/js/themejs/so_megamenu.js');
        // $.getScript('assets/js/themejs/addtocart.js');
        // $.getScript('assets/js/themejs/cpanel.js');
        // $.getScript('assets/js/plugins/star-rating.js');      
      });
  }
  public getUserWishlist(user){
    this.authService.getsEntite("api/customers-wishlist/"+user.customer_id).then((data:any)=>{
      this.appService.Data.wishList = data
      // //console(this.products);
     
    });
  }
  ngOnInit() {
    this.userProfile = JSON.parse(localStorage.getItem("markpediausersessionid")); 
    this.user = this.userProfile;
    ////console("user", this.user);
    this.invokeStripe()
    
    if(this.user){
      this.getUserWishlist(this.user.user);
    }
  
    if(this.appService.Data.cartList.length == 0){
      let list = JSON.parse(localStorage.getItem('cartList'))
      if(list){
        this.appService.Data.cartList=list
        this.appService.Data.totalCartCount =JSON.parse(localStorage.getItem('totalCartCount')) 
        this.appService.Data.totalPrice = JSON.parse(localStorage.getItem('totalPrice'))
      }
    }

    

    // this.loadCSS("assets/css/themecss/so-newletter-popup.css");
    // this.loadCSS("assets/css/themecss/lib.css");
    // this.loadCSS("assets/js/minicolors/miniColors.css");
    // this.loadCSS("assets/css/themecss/so_searchpro.css");
    // this.loadCSS("assets/css/themecss/so_megamenu.css");
    // this.loadCSS("assets/css/themecss/so-categories.css");
    // this.loadCSS("assets/css/themecss/so-listing-tabs.css");
    // this.loadCSS("assets/css/themecss/so-newletter-popup.css");
    // this.loadCSS("assets/css/footer/footer2.css");
    // this.loadCSS("assets/css/header/header2.css");
    // this.loadCSS("assets/css/responsive.css");
    //console(this.userProfile); 

   // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh    
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  loadCSS(url) {
    // Create link
    let link = document.createElement('link');
    link.href = url;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    
    let head = document.getElementsByTagName('head')[0];
    let links = head.getElementsByTagName('link');
    let style = head.getElementsByTagName('style')[0];
    
    // Check if the same style sheet has been loaded already.
    let isLoaded = false;  
    for (var i = 0; i < links.length; i++) {
      var node = links[i];
      if (node.href.indexOf(link.href) > -1) {
        isLoaded = true;
      }
    }
    if (isLoaded) return;
    head.insertBefore(link, style);
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0,0);
        } 
      }
    })  
  }

  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_51JVKNBLWVGsqrNVIFHOeElmhArjF4eLZV9jsvjvLpYIe5O0DhoDsNJTz5pDR0nSzfLpgyOZKuvCrg3tPsHHsyWJ700ZkGDaYfo',
          locale: 'auto',
          token: function (stripeToken: any) {
            //console(stripeToken);
            alert('Payment has been successfull!');
          },
        });
      };
      window.document.body.appendChild(script);
    }
  }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
            gtag('config', 'G-CTWTCDTP5B',
                {
                    page_path: event.urlAfterRedirects
                }
            );
        });
}
}

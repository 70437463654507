import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss']
})
export class LangComponent implements OnInit {  
  languages: string[] = ["en", "fr", "chn", "de", "ru", "tr"]
  public language:any = JSON.parse(localStorage.getItem('MarkpediaLanguage')); 
  constructor(public translateService: TranslateService) { 
    //console("translateService.getLangs()", translateService.getLangs())
    if (this.language != null) {
      this.translateService.use(this.language);   
    }
  }

  ngOnInit() { } 

  public changeLang(lang:string){ 
    this.translateService.use(lang);   
    localStorage.setItem('MarkpediaLanguage', JSON.stringify(lang))

  } 

}

<footer class="footer-container typefooter-2">
    <!-- Footer Top Container -->
    <section class="footer-top">
        <div class="container">
            <div class="row">
                <div class="contnet-footer-top">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 socials-footer">
                        <h3 class="title-footer-top">Follow Socials</h3>
                        <ul class="socials">
                            <li class="facebook"><a class="_blank" href="https://www.facebook.com/MagenTech" target="_blank"><i class="fa fa-facebook"></i></a>
                            </li>
                            <li class="twitter"><a class="_blank" href="https://twitter.com/smartaddons" target="_blank"><i class="fa fa-twitter"></i></a>
                            </li>
                            <li class="google_plus"><a class="_blank" href="https://plus.google.com/u/0/+Smartaddons/posts" target="_blank"><i class="fa fa-google-plus"></i></a>
                            </li>
                            <li class="pinterest"><a class="_blank" href="https://www.pinterest.com/smartaddons/" target="_blank"><i class="fa fa-skype"></i></a>
                            </li>
                            <li class="pinterest"><a class="_blank" href="https://www.pinterest.com/smartaddons/" target="_blank"><i class="fa fa-pinterest"></i></a>
                            </li>
                            <li class="pinterest"><a class="_blank" href="https://www.pinterest.com/smartaddons/" target="_blank"><i class="fa fa-youtube"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                        <h3 class="title-footer-top title-footer-bot">Sign Up For Newsletter</h3>
                        <div class="block_content">
                            <form method="post" id="signup" name="signup" class="form-group form-inline signup send-mail">
                                <div class="form-group">
                                    <div class="subcribe">
                                        <button class="btn btn-primary btn-default font-title" type="submit" onclick="return subscribe_newsletter();" name="submit">
                                        Sign in
                                        </button>
                                    </div>
                                    <div class="input-box">
                                        <input type="email" placeholder="Enter your email ..." value="" class="form-control" id="txtemail" name="txtemail" size="55">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Footer Top Container -->
    <section class="footer-middle ">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-style">
                    <div class="infos-footer box-footer">
                        <div class="module">
                            <h3 class="modtitle">Contact Info</h3>
                            <ul>
                                <li class="adres">No 40 Baria Sreet 133/2 NewYork City, NY, USD.</li>
                                <li class="mail">
                                    <a href="mailto:contact@opencartworks.com">contact@opencartworks.com</a>
                                </li>
                                <li class="phone">(+0214)0 315 215 - (+0214)0 315 215</li>
                                <li class="time">Open time: 8:00AM - 6:00PM</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12 col-style">
                    <div class="box-information box-footer">
                        <div class="module clearfix">
                            <h3 class="modtitle">Information</h3>
                            <div class="modcontent">
                                <ul class="menu">
                                    <li><a href="#">About Us</a></li>
                                    <li><a href="#">FAQ</a></li>
                                    <li><a href="#">Services</a></li>
                                    <li><a href="#">Support 24/7 page</a></li>
                                    <li><a href="#">Product</a></li>
                                    <li><a href="#">Product Support</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12 col-style">
                    <div class="box-service box-footer">
                        <div class="module clearfix">
                            <h3 class="modtitle">Services</h3>
                            <div class="modcontent">
                                <ul class="menu">
                                    <li><a href="#">Contact Us</a></li>
                                    <li><a href="#">Returns</a></li>
                                    <li><a href="#">Support</a></li>
                                    <li><a href="#">Site Map</a></li>
                                    <li><a href="#">Customer Service</a></li>
                                    <li><a href="#">Custom Link</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-style">
                    <div class="module  so-popular-tag box-footer">
                        <h3 class=" modtitle"><span>Popular Tags</span></h3>
                        <div class="modcontent">
                            <div class="box-content">   
                                <a href="#">Ground</a><a href="#">ipsum</a><a href="#">consectetur</a>
                                <a href="#">adipisicing</a><a href="#">eiusmod</a><a href="#">labore</a>
                                <a href="#">aliqua</a><a href="#">quis nostrud</a><a href="#">exercitation</a>
                                <a href="#">occaecat</a><a href="#">laboris</a><a href="#">occaecat</a> 
                                <a href="#">adipisicing</a><a href="#">eiusmod</a><a href="#">labore</a> 
                                <a href="#">aliqua</a><a href="#">quis nostrud</a><a href="#">exercitation</a><a href="#">occaecat</a><a href="#">laboris</a><a href="#">occaecat</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Footer Bottom Container -->
    <section class="footer-bottom ">
        <div class="container">
            <div class="row">
                <div class="content-footer-bottom">
                    <div class="col-lg-6 col-md-5 col-sm-12 col-xs-12 payment-w hidden-sm">
                        <img src="./assets/image/catalog/demo/payment/payment.png" alt="imgpayment">
                    </div>
                    <div class="col-lg-6 col-md-7 col-sm-12 col-xs-12 copyright-w pull-right">
                        <div class="copyright">© 2018 Bestsmarket. All Rights Reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Footer Bottom Container -->
    <!--Back To Top-->
    <div class="back-to-top"><i class="fa fa-angle-up"></i></div>
</footer>
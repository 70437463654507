import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rating',
  template: `
    <span>
      <ng-container *ngFor="let star of stars">
        <i class="fas fa-star" [class.filled]="star"></i>
      </ng-container>
    </span>
  `,
  styles: [`
    .fa-star {
      color: #ddd; /* Couleur des étoiles vides */
    }

    .filled {
      color: #fba100; /* Couleur des étoiles dorées remplies */
    }
  `],
})
export class RatingComponent {
  @Input() rating: number;
  stars: boolean[];

  ngOnChanges() {
    const roundedRating = Math.round(this.rating * 2) / 2; // Arrondi à la moitié supérieure
    const filledStars = Math.floor(roundedRating);
    const hasHalfStar = roundedRating % 1 !== 0;

    this.stars = Array(5).fill(false).map((_, i) => i < filledStars || (i === filledStars && hasHalfStar));
  }
  

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 
import { NotFoundComponent } from './pages/profile/not-found/not-found.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
export const routes: Routes = [
    {path: 'auth', loadChildren:()=> import ('./auth/auth.module').then(m=>m.AuthModule)},
    // { 
    //     path: '', 
    //     component: PagesComponent, children: [
    //         { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
    //         { path:'tracking', component:TrackingDetailsComponent},
    //         { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), data: { breadcrumb: 'Sign In ' } },     
    //         { path:'membership', component:MembershipComponent},
    //         { path:'track', component:IndexComponent},
    //         { path:'dispute', component:DisputeComponent},
    //         { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule), data: { breadcrumb: 'Account Settings' } },
    //         { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule), data: { breadcrumb: 'Compare' } },
    //         { path: 'wishlist', loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistModule), data: { breadcrumb: 'Wishlist' } },
    //         { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'Cart' } },
    //         { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'Checkout' } },
    //         { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contact' } },
    //         // { path: 'sign-in', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule), data: { breadcrumb: 'Sign In ' } },
    //         { path: 'brands', loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule), data: { breadcrumb: 'Brands' } },
    //         { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), data: { breadcrumb: 'All Products' } },
    //         { path: 'category', loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule), data: { breadcrumb: 'Categories' } },
    //         { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: 'about us' } },
    //         { path: 'static', loadChildren: () => import('./pages/static-page/static.module').then(m => m.StaticModule), data: { breadcrumb: 'static page' } },
    //         { path: 'faq', loadChildren: () => import('./pages/service/service.module').then(m => m.ServiceModule), data: { breadcrumb: 'services' } },
    //         { path: 'blog-detail', loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: 'Blog' } },
    //         { path: 'logistic-service', loadChildren: () => import('./pages/logistic-service/logistic.module').then(m => m.LogisticModule), data: { breadcrumb: 'Logistic' } },
    //         { path: 'sell-on-markpedia', loadChildren: () => import('./pages/sell-on-markpedia/sell.module').then(m => m.SellModule), data: { breadcrumb: 'Sell' } },
    //         { path: 'product', loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule), data: { breadcrumb: 'product details' } },
          
    //         { path: 'spage', loadChildren: () => import('./spage/spage.module').then(m => m.StaticModule) },
    //     ]
    // },
    // { path: 'c-dashboard', loadChildren: () => import('./c_dashboard/dashboard.module').then(m => m.DashboardModule) },
    // { path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) },
    { path: '', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },
    // { path: 'vendor', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },  
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
    ]
})
export class AppRoutingModule { }
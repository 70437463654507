import { Component, Input, Output, EventEmitter, OnInit  } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() totalItems: number;
  @Input() itemsPerPage: number; 
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  currentPage = 1;
  visiblePages: number[] = [];
  maxVisiblePages = 5; // Nombre maximum de pages visibles (à ajuster selon vos préférences)

  ngOnInit(): void {
    this.updateVisiblePages();
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  get halfLimit(): number {
    return Math.floor(this.maxVisiblePages / 2);
  }

  // ... le reste du code du composant ...

  updateVisiblePages(): void {
    const start = Math.max(this.currentPage - this.halfLimit, 1);
    const end = Math.min(start + this.maxVisiblePages - 1, this.totalPages);

    this.visiblePages = Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

  gotoPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.pageChange.emit(this.currentPage);
      this.updateVisiblePages();
    }
  }
}
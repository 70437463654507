import { Attribute, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Category, Product, Product_Attribute } from './app.models';
import { environment } from 'src/environments/environment'; 
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators'
import { BehaviorSubject, Observable ,  throwError } from 'rxjs'; 
import { response } from 'express';
import { AuthenticationService } from './auth.service';
import { Router } from '@angular/router';
import { config } from 'src/config/local';

export class Data {
    constructor(public categories: Category[],
                public compareList: Product[],
                public wishList: any[],
                public cartList: Product[],
                public totalPrice: number,
                public attributes: Product_Attribute[],
                public totalCartCount: number) { }
}

@Injectable()
export class AppService {
    loading$ = new BehaviorSubject<any>(null);
    countries;
    code;

    // public host: string = "https://api.markpedia.com/api/";
    // public host_post: string = "https://api.markpedia.com/blog/";
    // public host_chat: string = "https://api.markpedia.com/chat/";

    
    public host: string = `${config.baseUrl}api/` ;
    public host_post: string = `${config.baseUrl}blog/`;
    public host_chat: string = `${config.baseUrl}chat/`;
    public static_post: string = `${config.baseUrl}staticpages/`;
    public host_faq: string = `${config.baseUrl}faqs/`;
    public base_url: string = `${config.baseUrl}`;


    public hostFake: string = environment.url;
    public Data = new Data(
        [], // categories
        [], // compareList
        [],  // wishList
        [],  // cartList
        null, //totalPrice,
        [],//products attributes
        0 //totalCartCount
    )
    
    public url = environment.url + '/assets/data/'; 

    constructor(public http:HttpClient, 
        public authService: AuthenticationService,
        public router : Router,
        public snackBar: MatSnackBar) { }
    
    public getCategories(): Observable<Category[]>{
        return this.http.get<Category[]>(this.url + 'categories.json');
    }
    public getMCategories(type): Observable<Category[]>{
        return this.http.get<Category[]>(type);
    }
   
    public getProducts(type): Observable<any[]>{        
        return this.http.get<any[]>(this.host+type);
    }
    public getPost(type): Observable<any>{        
        return this.http.get<any>(this.host_post+type);
    }
    
    public getStaticPage(type): Observable<any>{        
        return this.http.get<any>(this.static_post+type);
    }
    public getComments(type): Observable<any[]>{        
        return this.http.get<any[]>(this.host_post+type);
    }
    public postComments(url: string, article: any): Observable<any> { 
        return this.http.post(
          `${this.host_post}${url}`,
          article
        ).pipe(catchError(this.formatErrors));
      }
    public getFaq(type): Observable<any[]>{        
        return this.http.get<any[]>(this.host_faq+type);
    }

    public getStatic(type): Observable<any[]>{        
        return this.http.get<any[]>(this.base_url+type);
    }
    public getPostById(id): Observable<any[]>{        
        return this.http.get<any>(`${config.baseUrl}blog/posts/${id}/`);
    }
    public getCustomerChat(type): Observable<any[]>{        
        return this.http.get<any[]>(this.host_chat+type);
    }
    

    public getProductById(id): Observable<any>{
        return this.http.get<any>(`${config.baseUrl}api/marketplaceproducts/${id}/`);
    }
    public getMiniProductById(id): Observable<any>{
        return this.http.get<any>(`${config.baseUrl}api/get_mini_product/${id}/`);
    }
    public getDescriptionProductById(id): Observable<any>{
        return this.http.get<any>(`${config.baseUrl}api/get_description_product/${id}/`);
    }
    public getAttributeProductById(id): Observable<any>{
        return this.http.get<any>(`${config.baseUrl}api/get_marketplace_product_attributes_color_by_product/${id}/`);
    }
    public getInformationProductById(id): Observable<any>{
        return this.http.get<any>(`${config.baseUrl}api/get_marketplace_product_additional_informations_by_product/${id}/`);
    }
    public getCategorieByStore(id): Observable<any>{
        return this.http.get<any>(`${config.baseUrl}api/get_market_sellers_categorie/${id}/`);
    }
    public getImagesProductById(id): Observable<any>{
        return this.http.get<any>(`${config.baseUrl}api/get_images_product/${id}/`);
    }
    public getSellersById(id): Observable<any>{
        return this.http.get<any>(`${config.baseUrl}api/marketplacesellers/${id}/`);
    }
    public getProfileBySellerId(id): Observable<any>{
        return this.http.get<any>(`${config.baseUrl}api/marketplaceseller-profile/${id}/`);
    }

    

    public getBanners(): Observable<any[]>{
        return this.http.get<any[]>(this.url + 'banners.json');
    }

    public addToCompare(product:Product){
        let message, status;
        if(this.Data.compareList.filter(item=>item.id == product.id)[0]){
            message = 'The product ' + product.my_product_flat.name + ' already added to comparison list.'; 
            status = 'error';     
        }
        else{
            this.Data.compareList.push(product);
            message = 'The product ' + product.my_product_flat.name + ' has been added to comparison list.'; 
            status = 'success';  
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }

    public addToWishList(product:Product){
        let message, status;
        if(this.Data.wishList.filter(item=>item.product.id == product.id)[0]){
            message = 'The product ' + product.my_product_flat.name + ' already added to wish list.'; 
            status = 'error';     
        }
        else{
            let user  = JSON.parse(localStorage.getItem("markpediausersessionid")).user; 
            let customer_data = {
                item_options : "",
                shared:0,
                commentaire:"",
                additional:"",
                customer:user.customer_id,
                product:product.id
            }
            this.authService.createNewUser('api/wishlist/',customer_data).then((res:any)=>{
                this.Data.wishList.push(res) 
            }).catch((error)=>{

            })
            // this.Data.wishList.push(product);
            message = 'The product ' + product.my_product_flat.name + ' has been added to wish list.'; 
            status = 'success';  
        }
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    } 

    public addAttribute(attribute:Product_Attribute, product:Product){
    //    product.attributes.push(attribute);
    //    //console(product.attributes)
       
    //     if(this.Data.cartList.filter(item=>item.id == product.id)[0]){ 
    //         let item = this.Data.cartList.filter(item=>item.id == product.id)[0];
            
    //         item.cartCount = product.cartCount;
    //         if(item.attributes.filter(att=>att.value == attribute.value)[0]){

    //             let att = item.attributes.filter(att=>att.value == attribute.value)[0];
    //             item.cartCount = product.cartCount + 1;

    //         }else{
    //             item.attributes.push(attribute);  
    //         }
            
    //     }
    //     else{   

    //         this.Data.cartList.push(product);
    //     }        
    //     this.Data.cartList.forEach(product=>{
    //         this.Data.totalPrice = this.Data.totalPrice + (product.cartCount * product.price);
    //         this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
    //     });
    //     this.snackBar.open('You add attribute ' + attribute.name+'  ' + attribute.value+ ' of ' + product.my_product_flat.name + ' item to your cart', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
       

    }
    public addToCart(product:Product){

        let cart = localStorage.getItem('cart');
    
        let message, status;        
       
        this.Data.totalPrice = null;
        this.Data.totalCartCount = null;
       
            
 

        if(this.Data.cartList.filter(item=>item.id == product.id)[0]){ 
            let item = this.Data.cartList.filter(item=>item.id == product.id)[0];
            item.cartCount = product.cartCount;  
        }
        else{           
            this.Data.cartList.push(product);
        } 
               
        this.Data.cartList.forEach(product=>{
            this.Data.totalPrice = this.Data.totalPrice + (product.cartCount * product.price);
            this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
        });

        message = 'The product ' + product.my_product_flat.name + ' has been added to cart.'; 
        status = 'success';          
        this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    
        if(cart){
            localStorage.setItem('cartList',JSON.stringify(this.Data.cartList))
            localStorage.setItem('totalCartCount',JSON.stringify(this.Data.totalCartCount))
            localStorage.setItem('totalPrice',JSON.stringify(this.Data.totalPrice))
      
        }else{
            let user_local = JSON.parse(localStorage.getItem('markpediausersessionid'))
            
            if(user_local){
                let new_cart = {
                    customer_email: user_local?.user.email,
                    customer_first_name : user_local?.user.first_name,
                    customer_last_name :  user_local?.user.last_name,
                    is_gift :0,
                    items_count: this.Data.cartList.length,
                    items_qty :this.Data.cartList.length,
                    global_currency_code :"USD",
                    base_currency_code :"USD",
                    channel_currency_code :"USD",
                    cart_currency_code :"USD",
                    is_guest :0,
                    status:0,
                    is_active :1,
                    customer_id :user_local.user.user_id,
                    
                }
                //console("user local id",user_local?.user.user_id)
                let data = {
                    quantity:product.cartCount,
                    id:product.id,
                    price : product.price
            

                }  
                let url = "api/cart/";
                this.authService.createNewUser(url,new_cart).then((response)=>{
                  //console(response)
                  let cart = response
                  localStorage.setItem('cart',JSON.stringify(response))
                  }).catch((error)=>{
                    //console(error)
                  })
    

            }else{
                this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});

            }

        }
    }

    public resetProductCartCount(product:Product){
        product.cartCount = 0;
        let compareProduct = this.Data.compareList.filter(item=>item.id == product.id)[0];
        if(compareProduct){
            compareProduct.cartCount = 0;
        };
        let wishProduct = this.Data.wishList.filter(item=>item.id == product.id)[0];
        if(wishProduct){
            wishProduct.cartCount = 0;
        }; 
    }

    public getBrands(){
        return [  
            { name: 'aloha', image: 'assets/image/catalog/demo/product/250/7.jpg' },
            { name: 'aloha', image: 'assets/images/brands/aloha.png' },
            { name: 'dream', image: 'assets/images/brands/dream.png' },  
            { name: 'congrats', image: 'assets/images/brands/congrats.png' },
            { name: 'best', image: 'assets/images/brands/best.png' },
            { name: 'original', image: 'assets/images/brands/original.png' },
            { name: 'retro', image: 'assets/images/brands/retro.png' },
            { name: 'king', image: 'assets/images/brands/king.png' },
            { name: 'love', image: 'assets/images/brands/love.png' },
            { name: 'the', image: 'assets/images/brands/the.png' },
            { name: 'easter', image: 'assets/images/brands/easter.png' },
            { name: 'with', image: 'assets/images/brands/with.png' },
            { name: 'special', image: 'assets/images/brands/special.png' },
            { name: 'bravo', image: 'assets/images/brands/bravo.png' }
        ];
    }

    getIsoformcountries(country):string{

        this.countries = this.getCountries()
        let pays =this.countries.find(opt => opt.name.toLowerCase() == country.toLowerCase());
        this.code = pays.code.toLowerCase()
        return this.code;
    }
    public getCountries(){
        return [ 
            {name: 'Afghanistan', code: 'AF',}, 
            {name: 'Aland Islands', code: 'AX'}, 
            {name: 'Albania', code: 'AL'}, 
            {name: 'Algeria', code: 'DZ'}, 
            {name: 'American Samoa', code: 'AS'}, 
            {name: 'AndorrA', code: 'AD'}, 
            {name: 'Angola', code: 'AO'}, 
            {name: 'Anguilla', code: 'AI'}, 
            {name: 'Antarctica', code: 'AQ'}, 
            {name: 'Antigua and Barbuda', code: 'AG'}, 
            {name: 'Argentina', code: 'AR'}, 
            {name: 'Armenia', code: 'AM'}, 
            {name: 'Aruba', code: 'AW'}, 
            {name: 'Australia', code: 'AU'}, 
            {name: 'Austria', code: 'AT'}, 
            {name: 'Azerbaijan', code: 'AZ'}, 
            {name: 'Bahamas', code: 'BS'}, 
            {name: 'Bahrain', code: 'BH'}, 
            {name: 'Bangladesh', code: 'BD'}, 
            {name: 'Barbados', code: 'BB'}, 
            {name: 'Belarus', code: 'BY'}, 
            {name: 'Belgium', code: 'BE'}, 
            {name: 'Belize', code: 'BZ'}, 
            {name: 'Benin', code: 'BJ'}, 
            {name: 'Bermuda', code: 'BM'}, 
            {name: 'Bhutan', code: 'BT'}, 
            {name: 'Bolivia', code: 'BO'}, 
            {name: 'Bosnia and Herzegovina', code: 'BA'}, 
            {name: 'Botswana', code: 'BW'}, 
            {name: 'Bouvet Island', code: 'BV'}, 
            {name: 'Brazil', code: 'BR'}, 
            {name: 'British Indian Ocean Territory', code: 'IO'}, 
            {name: 'Brunei Darussalam', code: 'BN'}, 
            {name: 'Bulgaria', code: 'BG'}, 
            {name: 'Burkina Faso', code: 'BF'}, 
            {name: 'Burundi', code: 'BI'}, 
            {name: 'Cambodia', code: 'KH'}, 
            {name: 'Cameroon', code: 'CM'}, 
            {name: 'Canada', code: 'CA'}, 
            {name: 'Cape Verde', code: 'CV'}, 
            {name: 'Cayman Islands', code: 'KY'}, 
            {name: 'Central African Republic', code: 'CF'}, 
            {name: 'Chad', code: 'TD'}, 
            {name: 'Chile', code: 'CL'}, 
            {name: 'China', code: 'CN'}, 
            {name: 'Christmas Island', code: 'CX'}, 
            {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
            {name: 'Colombia', code: 'CO'}, 
            {name: 'Comoros', code: 'KM'}, 
            {name: 'Congo', code: 'CG'}, 
            {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
            {name: 'Cook Islands', code: 'CK'}, 
            {name: 'Costa Rica', code: 'CR'}, 
            {name: 'Cote D\'Ivoire', code: 'CI'}, 
            {name: 'Croatia', code: 'HR'}, 
            {name: 'Cuba', code: 'CU'}, 
            {name: 'Cyprus', code: 'CY'}, 
            {name: 'Czech Republic', code: 'CZ'}, 
            {name: 'Denmark', code: 'DK'}, 
            {name: 'Djibouti', code: 'DJ'}, 
            {name: 'Dominica', code: 'DM'}, 
            {name: 'Dominican Republic', code: 'DO'}, 
            {name: 'Ecuador', code: 'EC'}, 
            {name: 'Egypt', code: 'EG'}, 
            {name: 'El Salvador', code: 'SV'}, 
            {name: 'Equatorial Guinea', code: 'GQ'}, 
            {name: 'Eritrea', code: 'ER'}, 
            {name: 'Estonia', code: 'EE'}, 
            {name: 'Ethiopia', code: 'ET'}, 
            {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
            {name: 'Faroe Islands', code: 'FO'}, 
            {name: 'Fiji', code: 'FJ'}, 
            {name: 'Finland', code: 'FI'}, 
            {name: 'France', code: 'FR'}, 
            {name: 'French Guiana', code: 'GF'}, 
            {name: 'French Polynesia', code: 'PF'}, 
            {name: 'French Southern Territories', code: 'TF'}, 
            {name: 'Gabon', code: 'GA'}, 
            {name: 'Gambia', code: 'GM'}, 
            {name: 'Georgia', code: 'GE'}, 
            {name: 'Germany', code: 'DE'}, 
            {name: 'Ghana', code: 'GH'}, 
            {name: 'Gibraltar', code: 'GI'}, 
            {name: 'Greece', code: 'GR'}, 
            {name: 'Greenland', code: 'GL'}, 
            {name: 'Grenada', code: 'GD'}, 
            {name: 'Guadeloupe', code: 'GP'}, 
            {name: 'Guam', code: 'GU'}, 
            {name: 'Guatemala', code: 'GT'}, 
            {name: 'Guernsey', code: 'GG'}, 
            {name: 'Guinea', code: 'GN'}, 
            {name: 'Guinea-Bissau', code: 'GW'}, 
            {name: 'Guyana', code: 'GY'}, 
            {name: 'Haiti', code: 'HT'}, 
            {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
            {name: 'Holy See (Vatican City State)', code: 'VA'}, 
            {name: 'Honduras', code: 'HN'}, 
            {name: 'Hong Kong', code: 'HK'}, 
            {name: 'Hungary', code: 'HU'}, 
            {name: 'Iceland', code: 'IS'}, 
            {name: 'India', code: 'IN'}, 
            {name: 'Indonesia', code: 'ID'}, 
            {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
            {name: 'Iraq', code: 'IQ'}, 
            {name: 'Ireland', code: 'IE'}, 
            {name: 'Isle of Man', code: 'IM'}, 
            {name: 'Israel', code: 'IL'}, 
            {name: 'Italy', code: 'IT'}, 
            {name: 'Jamaica', code: 'JM'}, 
            {name: 'Japan', code: 'JP'}, 
            {name: 'Jersey', code: 'JE'}, 
            {name: 'Jordan', code: 'JO'}, 
            {name: 'Kazakhstan', code: 'KZ'}, 
            {name: 'Kenya', code: 'KE'}, 
            {name: 'Kiribati', code: 'KI'}, 
            {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
            {name: 'Korea, Republic of', code: 'KR'}, 
            {name: 'Kuwait', code: 'KW'}, 
            {name: 'Kyrgyzstan', code: 'KG'}, 
            {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
            {name: 'Latvia', code: 'LV'}, 
            {name: 'Lebanon', code: 'LB'}, 
            {name: 'Lesotho', code: 'LS'}, 
            {name: 'Liberia', code: 'LR'}, 
            {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
            {name: 'Liechtenstein', code: 'LI'}, 
            {name: 'Lithuania', code: 'LT'}, 
            {name: 'Luxembourg', code: 'LU'}, 
            {name: 'Macao', code: 'MO'}, 
            {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
            {name: 'Madagascar', code: 'MG'}, 
            {name: 'Malawi', code: 'MW'}, 
            {name: 'Malaysia', code: 'MY'}, 
            {name: 'Maldives', code: 'MV'}, 
            {name: 'Mali', code: 'ML'}, 
            {name: 'Malta', code: 'MT'}, 
            {name: 'Marshall Islands', code: 'MH'}, 
            {name: 'Martinique', code: 'MQ'}, 
            {name: 'Mauritania', code: 'MR'}, 
            {name: 'Mauritius', code: 'MU'}, 
            {name: 'Mayotte', code: 'YT'}, 
            {name: 'Mexico', code: 'MX'}, 
            {name: 'Micronesia, Federated States of', code: 'FM'}, 
            {name: 'Moldova, Republic of', code: 'MD'}, 
            {name: 'Monaco', code: 'MC'}, 
            {name: 'Mongolia', code: 'MN'}, 
            {name: 'Montserrat', code: 'MS'}, 
            {name: 'Morocco', code: 'MA'}, 
            {name: 'Mozambique', code: 'MZ'}, 
            {name: 'Myanmar', code: 'MM'}, 
            {name: 'Namibia', code: 'NA'}, 
            {name: 'Nauru', code: 'NR'}, 
            {name: 'Nepal', code: 'NP'}, 
            {name: 'Netherlands', code: 'NL'}, 
            {name: 'Netherlands Antilles', code: 'AN'}, 
            {name: 'New Caledonia', code: 'NC'}, 
            {name: 'New Zealand', code: 'NZ'}, 
            {name: 'Nicaragua', code: 'NI'}, 
            {name: 'Niger', code: 'NE'}, 
            {name: 'Nigeria', code: 'NG'}, 
            {name: 'Niue', code: 'NU'}, 
            {name: 'Norfolk Island', code: 'NF'}, 
            {name: 'Northern Mariana Islands', code: 'MP'}, 
            {name: 'Norway', code: 'NO'}, 
            {name: 'Oman', code: 'OM'}, 
            {name: 'Pakistan', code: 'PK'}, 
            {name: 'Palau', code: 'PW'}, 
            {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
            {name: 'Panama', code: 'PA'}, 
            {name: 'Papua New Guinea', code: 'PG'}, 
            {name: 'Paraguay', code: 'PY'}, 
            {name: 'Peru', code: 'PE'}, 
            {name: 'Philippines', code: 'PH'}, 
            {name: 'Pitcairn', code: 'PN'}, 
            {name: 'Poland', code: 'PL'}, 
            {name: 'Portugal', code: 'PT'}, 
            {name: 'Puerto Rico', code: 'PR'}, 
            {name: 'Qatar', code: 'QA'}, 
            {name: 'Reunion', code: 'RE'}, 
            {name: 'Romania', code: 'RO'}, 
            {name: 'Russian Federation', code: 'RU'}, 
            {name: 'RWANDA', code: 'RW'}, 
            {name: 'Saint Helena', code: 'SH'}, 
            {name: 'Saint Kitts and Nevis', code: 'KN'}, 
            {name: 'Saint Lucia', code: 'LC'}, 
            {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
            {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
            {name: 'Samoa', code: 'WS'}, 
            {name: 'San Marino', code: 'SM'}, 
            {name: 'Sao Tome and Principe', code: 'ST'}, 
            {name: 'Saudi Arabia', code: 'SA'}, 
            {name: 'Senegal', code: 'SN'}, 
            {name: 'Serbia and Montenegro', code: 'CS'}, 
            {name: 'Seychelles', code: 'SC'}, 
            {name: 'Sierra Leone', code: 'SL'}, 
            {name: 'Singapore', code: 'SG'}, 
            {name: 'Slovakia', code: 'SK'}, 
            {name: 'Slovenia', code: 'SI'}, 
            {name: 'Solomon Islands', code: 'SB'}, 
            {name: 'Somalia', code: 'SO'}, 
            {name: 'South Africa', code: 'ZA'}, 
            {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
            {name: 'Spain', code: 'ES'}, 
            {name: 'Sri Lanka', code: 'LK'}, 
            {name: 'Sudan', code: 'SD'}, 
            {name: 'Suriname', code: 'SR'}, 
            {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
            {name: 'Swaziland', code: 'SZ'}, 
            {name: 'Sweden', code: 'SE'}, 
            {name: 'Switzerland', code: 'CH'}, 
            {name: 'Syrian Arab Republic', code: 'SY'}, 
            {name: 'Taiwan, Province of China', code: 'TW'}, 
            {name: 'Tajikistan', code: 'TJ'}, 
            {name: 'Tanzania, United Republic of', code: 'TZ'}, 
            {name: 'Thailand', code: 'TH'}, 
            {name: 'Timor-Leste', code: 'TL'}, 
            {name: 'Togo', code: 'TG'}, 
            {name: 'Tokelau', code: 'TK'}, 
            {name: 'Tonga', code: 'TO'}, 
            {name: 'Trinidad and Tobago', code: 'TT'}, 
            {name: 'Tunisia', code: 'TN'}, 
            {name: 'Turkey', code: 'TR'}, 
            {name: 'Turkmenistan', code: 'TM'}, 
            {name: 'Turks and Caicos Islands', code: 'TC'}, 
            {name: 'Tuvalu', code: 'TV'}, 
            {name: 'Uganda', code: 'UG'}, 
            {name: 'Ukraine', code: 'UA'}, 
            {name: 'United Arab Emirates', code: 'AE'}, 
            {name: 'United Kingdom', code: 'GB'}, 
            {name: 'United States', code: 'US'}, 
            {name: 'United States Minor Outlying Islands', code: 'UM'}, 
            {name: 'Uruguay', code: 'UY'}, 
            {name: 'Uzbekistan', code: 'UZ'}, 
            {name: 'Vanuatu', code: 'VU'}, 
            {name: 'Venezuela', code: 'VE'}, 
            {name: 'Viet Nam', code: 'VN'}, 
            {name: 'Virgin Islands, British', code: 'VG'}, 
            {name: 'Virgin Islands, U.S.', code: 'VI'}, 
            {name: 'Wallis and Futuna', code: 'WF'}, 
            {name: 'Western Sahara', code: 'EH'}, 
            {name: 'Yemen', code: 'YE'}, 
            {name: 'Zambia', code: 'ZM'}, 
            {name: 'Zimbabwe', code: 'ZW'} 
        ]
    }

    public getMonths(){
        return [
            { value: '01', name: 'January' },
            { value: '02', name: 'February' },
            { value: '03', name: 'March' },
            { value: '04', name: 'April' },
            { value: '05', name: 'May' },
            { value: '06', name: 'June' },
            { value: '07', name: 'July' },
            { value: '08', name: 'August' },
            { value: '09', name: 'September' },
            { value: '10', name: 'October' },
            { value: '11', name: 'November' },
            { value: '12', name: 'December' }
        ]
    }

    public getYears(){
        return ["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030" ]
    }

    public getDeliveryMethods(){
        return [
            { value: 'Air', name: 'Express Delivery (Plane)', desc: '$10/Kg -/ Delivery in 3 to 7 business Days', shippingAdd:{company:'MARKPEDIA AIrline ', adrress:'GOUANZOU', phone:'+81 6523653254',zip:'54215421'}, price:10 },
            { value: 'Boat', name: 'Standard Delivery (Boat)', desc: '$6/Kg -/ Delivery in 30 to 45 business Days' ,shippingAdd:{company:'MARKPEDIA BOTH AIRLINE ', adrress:'BEJIN', phone:'+81 5646656',zip:'65321487'}, price:6},     
            { value: 'Both', name: 'Express Delivery (Plane and Boat)', desc: '$10/Kg -/ Delivery in 3 to 7 business Days',shippingAdd:{company:'MARKPEDIA SEA ', adrress:'PEKIN', phone:'+81 63968552',zip:'54215421'}, price:10 },

            // { value: 'express', name: 'Express Delivery', desc: '$29.99 / Delivery in 1 business Days' }
        ]
    }

    post(url: string, article: any): Observable<any> { 
        return this.http.post(
          `${this.host}${url}`,
          article
        ).pipe(catchError(this.formatErrors));
      }
      
      get(url: string): Observable<any> {  
        return this.http.get<any>(`${this.host}${url}`)  
          .pipe(  
            catchError(this.formatErrors)  
          );  
      }   
    
      put(url: string, body: any): Observable<any> {
        return this.http.put(
          `${this.host}${url}`,
          body
        ).pipe(catchError(this.formatErrors));
      }
    
      delete(url: string): Observable<{}> {  
        return this.http.delete<any>(`${this.host}${url}`)  
          .pipe(  
            catchError(this.formatErrors)  
          );  
      }  
      
    
      private formatErrors(error: any) {
        return  throwError(error.error);
      }

} 
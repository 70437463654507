import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { Data, AppService } from '../../app.service';
import { Product } from "../../app.models";
import { Settings, AppSettings } from 'src/app/app.settings';
import { AuthenticationService } from 'src/app/auth.service';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-products-carousel',
  templateUrl: './products-carousel.component.html',
  styleUrls: ['./products-carousel.component.scss']
})
export class ProductsCarouselComponent implements OnInit {

  @Input('products') products: Array<Product> = [];
  public config: SwiperConfigInterface = {};
  public currency:any = JSON.parse(localStorage.getItem('MarkpediaDevise')); 
  public settings: Settings;
  public valueR: number = 4;
  public maxR: number = 5;
  public disabled: boolean = false;
  public dense: boolean = true;
  public readonly: boolean = true;
  public color: ThemePalette = 'warn';
  constructor(public authService: AuthenticationService,public appSettings:AppSettings, public appService:AppService, public dialog: MatDialog, private router: Router) { 
    this.settings = this.appSettings.settings;
    this.authService?.currency$.subscribe((currency: any) =>{
      this.currency=currency
   })
  }

  ngOnInit() { }
  
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,       
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 5
        },
        1500: {
          slidesPerView: 6
        }
      }
    }
  }

  public openProductDialog(product){   
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
        direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.id, product.name]); 
      }
    });
  }

  
  public language:any = JSON.parse(localStorage.getItem('MarkpediaLanguage')); 
  public tempsEcoule = (date: string) => {
    let temps = "58 min"
    const currentDate = new Date()
    const current_year = currentDate.getFullYear()
    const current_month = currentDate.getMonth() + 1
  
    const oldDate = new Date(date)
    const old_year = oldDate.getFullYear()
    const old_month = oldDate.getMonth() + 1
     
    if (this.language != null) {
      if (current_year >= old_year) {
        
        if (this.language == "chn") {
          temps = (current_year - old_year + 1).toString() + " 年"
          return temps
        }else if (this.language == "fr") {
          temps = (current_year - old_year + 1).toString() + " an(s)"
          return temps
        }else{
          temps = (current_year - old_year + 1).toString() + " year(s)"
          return temps
        }
        
      }
    
      // if (current_month >= old_month) {
      //   if (this.language == "chn") {
      //     temps = (current_month - old_month).toString() + " 月"
      //     return temps
      //   }else if (this.language == "fr") {
      //     temps = (current_month - old_month).toString() + " mois"
      //     return temps
      //   }else{
      //     temps = (current_month - old_month).toString() + " month"
      //     return temps
      //   }
       
      // }
    }else{
      if (current_year >= old_year) {
        temps = (current_year - old_year + 1).toString() + " year"
        return temps
      }
    
      // if (current_month >= old_month) {
      //   temps = (current_month - old_month).toString() + " month"
      //   return temps
      // }
    }
   
    
  }


  
  public removeSlash(name: string){
    return encodeURIComponent(name)
    
  }


}
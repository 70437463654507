<div *ngIf="products" class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper h-100">      
        <div *ngFor="let prod of products" class="swiper-slide">
          <div class="wsk-cp-product">
            <div class="wsk-cp-img">
              <a routerLink="/products/{{prod.id}}/{{removeSlash(prod.my_product_flat.name)}}" [title]="prod.my_product_flat.name" target="_self" > 
                <img  [src]="prod.images[0].path" alt="Product"  width="auto"/></a>
            </div>
            <div class="wsk-cp-text">
              <!-- <div class="category">
                <span><a style="color:#fff" routerLink="/products/{{prod?.my_product_flat?.product_id?.categorie?.name}}">{{prod?.my_product_flat?.product_id?.categorie?.name}}</a></span>
              </div> -->
              <div class="title-product">
                <a style="color: black; text-decoration: none;"  routerLink="/products/{{prod.id}}/{{removeSlash(prod.my_product_flat.name)}}" [title]="prod.my_product_flat.name" target="_self" > 
                  <h3>{{prod?.my_product_flat?.name}}</h3>
                </a>
                
              </div>
              
              <!-- <div class="description-prod"> 
                <p>{{prod.my_product_flat.description}}</p>
              </div> -->
              <div style="margin-bottom: 10px;">
                <ng-container>
                    <div class="rating">
                      <div [class.dense]="dense">
                        <ngx-material-rating
                          [color]="color"
                          [disabled]="disabled"
                          [dense]="dense"
                          [readonly]="readonly"
                          [(ngModel)]="valueR"
                          [max]="maxR"
                        >
                        </ngx-material-rating>
                      </div>
                    </div>
                  </ng-container>
            </div>
            <div style="float: right; position: relative; right: 0; top: -20px;">
                {{tempsEcoule(prod?.created_at)}}
              </div>
              <div class="card-footer">
                <div class="wcf-left"><span style="color: #A10710; text-decoration: none;"  class="price">{{currency?.symbol}} {{prod?.price*currency?.value | number : '1.1-2' }}</span></div>
                <div class="wcf-right"><a style="cursor: pointer;" (click)="addToCart(prod)" class="buy-btn"><i class="fa fa-shopping-cart"></i></a></div>
              </div>
            </div>
          </div>
            
        </div>
    </div>
    <button  class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
    <button class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button> 
</div>